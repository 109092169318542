.cookie_manager {
    /* Colors */
    --clr-bg: #FFFFFF;
    --clr-bg-border: #E53C65;

    --clr-text: #000000;
    --clr-btn-text: #FFFFFF;
    --clr-btn-text-settings: #3E3E3E;

    --clr-btn-border: #3E3E3E;
    --clr-btn-hover-text: #FFFFFF;

    --clr-btn-bg-all: #3E3E3E;
    --clr-btn-bg-settings: #FFFFFF;
    --clr-btn-bg-selected: #E53C65;
    --clr-btn-bg-option: #3E3E3E;
    --clr-btn-bg-hover: #A5A3A3;

    /* Font sizes */
    --fs-title: 1.25rem;
    --fs-mobile-title: 1.6rem;

    --fs-text: 0.96rem;
    --fs-mobile-text: 1.2rem;

    --fs-btn-text: 0.81rem;
    --fs-btn-mobile-text: 1rem;

    --fs-btn-option-text: 0.95rem;
}

.cookie_manager {
    position: fixed;
    max-width: 50rem;
    background-color: var(--clr-bg);
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 0.8rem 1.1rem 0.8rem 1.4rem;
    z-index: 100;
    border-left: 0.5rem solid var(--clr-bg-border);
    text-align: left;
}

.cookie_manager h2{
    font-size: var(--fs-title);
    font-family: var(--font-geist-bold);
    color: var(--clr-text);
    margin: 0;
    padding-bottom: 0.2rem;
}

.cookie_manager p {
    font-size: var(--fs-text);
    font-family: var(--font-geist-light);
    color: var(--clr-text);
    margin: 0;
    line-height: 1.525rem;
}

.consent_wrapper {
    display: flex;
    margin-right: 5rem;
}

.settings_wrapper {
    display: flex;
}

.settings_text {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-top: 0.7rem;
    margin-right: 0.5rem;
}

/* Right-side buttons */
.buttons_wrapper_settings,
.buttons_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
    flex-shrink: 0;
}

.button {
    font-size: var(--fs-btn-text);
    font-family: var(--font-geist-regular);
    border: 0.15rem solid var(--clr-btn-border);
    border-radius: 0.28rem;
    padding: 0.55rem 0.9rem;
    cursor: pointer;
}

.button:hover {
    background-color: var(--clr-btn-bg-hover);
    color: var(--clr-btn-hover-text);
}

.button_izberi_vse {
    background-color: var(--clr-btn-bg-all);
    color: var(--clr-btn-text);
}

.button_nastavitve {
    background-color: var(--clr-btn-bg-settings);
    color: var(--clr-btn-text-settings);
}

.button_settings {
    font-size: var(--fs-btn-option-text);
    font-family: var(--font-geist-regular);
    color: var(--clr-btn-text);
    border-radius: 0.28rem;
    margin-right: 0.6rem;
    cursor: pointer;
}

.button_da {
    border: 0.15rem solid var(--clr-btn-bg-selected);
    background-color: var(--clr-btn-bg-selected);
    padding: 0.5rem 0.6rem;
    align-self: end;
}

.option_button {
    display: flex;
    align-items: center;
}

.option_da,
.option_ne{
    background-color: var(--clr-btn-bg-option);
    border: 0.15rem solid var(--clr-btn-bg-option);
    padding: 0.5rem 0.7rem;
}

.option_da {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.option_ne {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    margin-right: unset;
}

.selected {
    background-color: var(--clr-btn-bg-selected);
    border-color: var(--clr-btn-bg-selected);
    transition: all 1s;
}

.option:not(.selected){
    background-color: var(--clr-btn-bg-option);
    border-color: var(--clr-btn-bg-selected);
    transition: all 1.5s;
}

@media (max-width: 1280px) {
    .consent_wrapper{
        margin-right: 0.8rem;
    }

    .button_izberi_vse:hover {
        background-color: var(--clr-btn-bg-all);
        color: var(--clr-btn-text);
    }
    
    .button_nastavitve:hover {
        background-color: var(--clr-btn-bg-settings);
        color: var(--clr-btn-text-settings);
    }
}

@media (max-width: 836px){
    .buttons_wrapper_settings,
    .buttons_wrapper {
        gap: 1.5rem;
        margin-bottom: 1rem;
    }

    .button_da,
    .option_da,
    .option_ne {
        padding: 0.448rem 0.6rem;
    }
}

@media (max-width: 768px) {
    .cookie_manager {
        max-width: unset;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        padding: 1rem 1.1rem 1.2rem 1.4rem;
        border-left: 0.7rem solid var(--clr-bg-border);
    }
    
    .cookie_manager h2{
        font-size: clamp(1.2rem, 5vw, var(--fs-mobile-title));
        font-family: var(--font-geist-bold);
        color: var(--clr-mobile-text);
        padding-bottom: 0.5rem;
    }
    
    .cookie_manager p {
        font-size: clamp(0.7rem, 4.5vw, var(--fs-mobile-text));
        line-height: clamp(1.1rem, 5.3vw, 1.6rem);
        font-family: var(--font-geist-light);
        color: var(--clr-mobile-text);
    }
    
    .consent_wrapper {
        margin-right: unset;
    }
    
    .settings_text {
        flex-direction: column;
        gap: unset;
        align-items: unset;
        padding-top: 0.7rem;
        margin-right: unset;
    }

    .buttons_wrapper_settings {
        gap: unset;
        margin-left: 1rem;
        justify-content: center;
        gap: 4rem;
        margin-top: clamp(0.2rem, 3vw, 4rem);
    }
    
    .buttons_wrapper {
        min-width: 70%;
        align-self: center;
        padding-top: 1rem;
        display: flex;
        gap: 0.9rem;
        margin-bottom: unset;
    }
    
    .button {
        font-size: var(--fs-btn-mobile-text);
        font-family: var(--font-geist-semibold);     
    }
    
    
    .button_settings {
        font-size: var(--fs-btn-mobile-text);
        font-family: var(--font-geist-semibold);   
    }
    
    .button_da,
    .option_da,
    .option_ne {
        padding: clamp(0.6rem, 1.5vw, 1rem);
    }
    .button_da {
        margin-right: unset;
        margin-bottom: unset;
    }
    
    .option_button {
        align-self: unset;
        margin-top: unset;
    }
    
    .option_da{
        margin-right: unset;
    }
}