:root {
  --clr-white: #FFFFFF;
  --clr-black: #000000;
  --clr-bg: #3E3E3E;

  /* Fonts */
  --clr-title: var(--clr-spcl-blue);
  --clr-primary: #FFFFFF;
  --clr-secondary: #BBBBBC;
}

.home {
  --fs-title: 1.75rem;
  --fs-text: 1.17rem;

  --lh-title: 1.75rem;
  --lh-text: 1.75rem;
}

body {
  background-color: var(--clr-bg);
  margin: 0;
}

.home {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
}

.header,
.main {
  max-width: 80%;
  align-self: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main h2 {
  color: var(--clr-title);
  font-family: var(--font-geist-bold);
  font-size: var(--fs-title);
  line-height: var(--lh-title);
  margin: 0;
  margin-bottom: 0.8rem;
}

.main p {
  color: var(--clr-primary);
  font-family: var(--font-geist-regular);
  font-size: var(--fs-text);
  line-height: var(--lh-text);
  margin: 0;
}

.graficno_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 85%;
  margin-left: 2.3rem;
  margin-top: 3.8rem;
}

.banner_container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-self: center;
}

.storitve_container,
.koristi_container {
  display: flex;
  justify-content: space-between;
  margin-top: 4.9rem;
  gap: 2rem;
}

.storitve_container > *,
.koristi_container > * {
  max-width: 50%;
}

/* Image styles */
.storitve_banner,
.koristi_banner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
}

/* Hover image effects */
.banner_container:hover .storitve_banner,
.banner_container:hover .koristi_banner {
  transform: scale(1.1);
}

.storitve_banner,
.koristi_banner {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.storitve,
.koristi {
  width: 40%;
  align-self: center;
}

.list_item {
  display: flex;
}

.list_item::before {
  content: '-';
  padding-right: 0.3rem;
}

.button_naroci {
  float: right;
  margin-top: 4.3%;
  margin-right: 7%;
}

.scroll_puscica {
  height: 4.5rem;
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  cursor: pointer;
  display: none;
}

.scroll_puscica.visible {
  display: block;
}

@media (max-width: 1280px) {
  .home {
    --fs-title: 3vw;
    --fs-text: 2.1vw;

    --lh-title: 3.5vw;
    --lh-text: 3.1vw;
  }
}

@media (max-width: 1080px) {
  .storitve_container,
  .koristi_container {
    margin-top: 3rem;
  }  
}

@media (max-width: 768px) {
  .home {
    --fs-title: 6vw;
    --fs-text: 4.1vw;

    --lh-title: 8vw;
    --lh-text: 6vw;
  }

  .main {
    max-width: 100%;
    box-sizing: border-box;
  }

  .button_naroci {
    margin-top: 8vw;
    margin-right: 1.3vw;
  }

  .graficno_container {
    max-width: 80%;
    margin: auto;
    margin-top: 16vw;
  }

  .storitve_container,
  .koristi_container {
    max-width: 100%;
    flex-direction: column;
    margin-top: 12.1%;
    gap: 0;
  }

  .storitve,
  .koristi {
    max-width: 80%;
    width: 100%;
    align-self: center;
  }

  .image_gallery_storitve,
  .image_gallery_koristi {
    max-width: 100%;
    width: 100%;
    order: 1;
    margin-top: 12.1%;
  }

  .scroll_puscica {
    right: 1rem;
    bottom: 0.5rem;
  } 
}
