.container {
    --clr-title: #26B5B5;

    --fs-h1: 56px;
    --fs-header-p: 23.06px;
    --fs-logo-p: 35px;
}

.title {
    color: var(--clr-title);
    text-align: left;
    margin: 3.42rem 0 1.9rem 0.85rem;
}
  
.title h1 {
    font-size: var(--fs-h1);
    font-family: var(--font-geist-semibold);
    line-height: 0;
}

.title p {
    font-size: var(--fs-header-p);
    font-family: var(--font-geist-regular);
    letter-spacing: 0.35px;
    color: var(--clr-spcl-blue);
    padding-top: 0.23rem;
    padding-left: 0.15rem;
}

.logo_container {
    text-align: center;
}

.logo_christmas,
.logo {
    max-width: 100%;
    margin-bottom: 0.4%;
    opacity: 1;
}

.logo {
    cursor: pointer;
}

.fade_out {
    opacity: 0;
}

.fade_in {
    animation: fadeIn 4s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.logo_text {
    font-size: var(--fs-logo-p);
    margin: 0;
    font-family: var(--font-geist-thin);
    color: var(--clr-primary);
    letter-spacing: 10px;
}

.line {
    width: 90%;
    height: 2px;
    position: absolute;
    left: 0;
    background-color: var(--clr-white);
    margin-top: 0.2rem;
}

/* Tablet styles */
@media (max-width: 1280px) {
    .container {
        --fs-logo-p: 3.5vw;
    }

    .title {
        margin: 3.4rem 0 1.9rem 0.6rem;
    }

    .title p {
        padding-top: 0.2vw;
    }

    .logo_container {
        padding-top: 0.35rem;
    }

    .logo_text {
        letter-spacing: 1vw;
        padding-top: 2.6%;
        padding-bottom: 1%;
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .container {
        --fs-h1: 8vw;
        --fs-header-p: 3.5vw;
        --fs-logo-p: 4vw;
    }

    .container {
        margin-top: 17%;
    }

    .title {
        margin: 3vw 0 1vw 0.4vw;
    }

    .title p {
        padding-top: unset;
    }
   
    .logo_christmas,
    .logo {
        padding-top: 3%;
    }

    .logo_text {
        letter-spacing: 0.8vw;
        padding-top: 5%;
        padding-bottom: 1.5%;
    }
}

@media (max-width: 520px) {
    .container {
        --fs-logo-p: 1.3rem;
    }

    .logo_text {
        letter-spacing: 0.1rem;
    }
}

@media (max-width: 385px) {
    .container {
        --fs-logo-p: 4vw;
    }
}