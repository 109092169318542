.container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.gallery_images {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image {
  width: 100vw;
  align-self: center;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: clamp(2rem, 8vw, 8rem);
  width: clamp(2rem, 8vw, 8rem);
  cursor: pointer;
}

.left_arrow {
  left: 0;
  z-index: 2;
}

.right_arrow {
  right: 0;
  z-index: 2;
}
