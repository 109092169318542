@font-face {
  font-family: "Geist Black";
  src: url('./assets/fonts/Geist-Black-BF6569491cec591.otf') format('opentype');
}
@font-face {
  font-family: "Geist Bold";
  src: url('./assets/fonts/Geist-Bold-BF6569491da5a14.otf') format('opentype');
}
@font-face {
  font-family: "Geist Light";
  src: url('./assets/fonts/Geist-Light-BF6569491dc9c01.otf')format('opentype');
}
@font-face {
  font-family: "Geist Medium";
  src: url('./assets/fonts/Geist-Medium-BF6569491e1c281.otf')format('opentype');
}
@font-face {
  font-family: "Geist Regular";
  src: url('./assets/fonts/Geist-Regular-BF6569491e3eff1.otf') format('opentype');
}
@font-face {
  font-family: "Geist Semibold";
  src: url('./assets/fonts/Geist-SemiBold-BF6569491e8c368.otf') format('opentype');
}  
@font-face {
  font-family: "Geist Thin";
  src: url('./assets/fonts/Geist-Thin-BF6569491e12aff.otf') format('opentype');
}
@font-face {
  font-family: "Geist UltraBlack";
  src: url('./assets/fonts/Geist-UltraBlack-BF6569491ecdc4d.otf') format('opentype');
}
@font-face {
  font-family: "Geist UltraLight";
  src: url('./assets/fonts/Geist-UltraLight-BF6569491f3e71d.otf') format('opentype');
}
  
:root {
  --font-geist-black: "Geist Black";
  --font-geist-bold: "Geist Bold";
  --font-geist-light: "Geist Light";
  --font-geist-medium: "Geist Medium";
  --font-geist-regular: "Geist Regular";
  --font-geist-semibold: "Geist Semibold";
  --font-geist-thin: "Geist Thin";
  --font-geist-ultrablack: "Geist UltraBlack";
  --font-geist-ultralight: "Geist UltraLight";

   /* Primary website colors */
  --clr-spcl-red: #E53C65;
  --clr-spcl-blue: #26B5B5;
  --clr-spcl-gray: #3E3E3E;

  /* Backgrounds */
  --clr-modal: #333333B3;
  --clr-nav-arrow: #d4d2d2;
}
  