.container {
    display: grid;
    grid-template-columns: 0fr 0fr 0fr;
    gap: 60px;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1.2rem;
    margin-top: 6.1rem;
}

.platform_container_l,
.platform_container_f,
.platform_container_i {
    display: flex;
    cursor: pointer;
}

.platform_container_f {
    align-items: center;
}

.platform_container_i {
    align-items: flex-end;
}

.facebook,
.instagram,
.linkedIn {
    display: flex;
}

.logo_instagram,
.logo_facebook,
.logo_linkedIn {
    width: 105px;
    height: auto;
}

.logo_facebook {
    width: 100px;
}

.logo_linkedIn {
    margin-right: 2px;
}


@media (max-width: 768px) {
    .container {
        width: 80%;
        gap: unset;
        justify-content: space-around;
        margin-top: 20vw;
        margin-bottom: 6vw;
    }

    .logo_facebook,
    .logo_instagram,
    .logo_linkedIn {
        width: 20vw;
    }

    .logo_linkedIn {
        margin-right: 1vw;
    }
}
