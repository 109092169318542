.container {
    width: 100%;
    flex-direction: column;
    text-align: right;
    align-items: center;
    gap: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.kontakt {
    display: flex;
    align-items: center;
    gap: 2.3rem;
    margin-right: 7.5rem;
}

.text h2 {
    padding-bottom: 0.5rem;
    margin: 0;
}

.text p {
    font-family: var(--font-geist-bold);
    line-height: calc(var(--fs-title) + 0.45rem);
    font-size: var(--fs-title);
    color: var(--clr-primary);
}

.qr {
    height: 220px;
    width: 220px;
}

.button {
    margin-left: 1rem;
    position: relative;
}

@media (max-width: 1280px) {
    .container {
        margin-top: 9rem;
    }

    .kontakt {
        margin-right: 12%;
    }

    .button {
        margin-top: 0.5rem;
    }
}

@media (max-width: 1080px) {
    .container {
        margin-top: 6rem;
    }

    .button {
        margin-top: unset;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 11vw;
        gap: 4vw;
    }

    .kontakt {
        text-align: center;
        margin-right: unset;
    }
    
    .text h2 {
        padding-bottom: unset;
    }

    .text p {
        line-height: calc(var(--fs-title) + 2vw);
    }

    .qr {
        display: none;
    }

    .button {
        margin-left: unset;
        position: relative;
    }
}
