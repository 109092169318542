.container {
    --clr-text: #FFFFFF;
    --clr-secondary: #BBBBBC;

    --clr-shadow: #00000088;
    --clr-scroll-track: #545457;
    --clr-scroll-thumb: #818184;

    --fs-client-h3: 1.2rem;
    --fs-client-h4: 1.2rem;
    --fs-client-p: 1.05rem;

    --lh-client-h3: 1.6rem;
    --lh-client-h4: 1.3rem;
}

.container {
    display: flex;
    max-height: 450px;
    max-width: 500px;
    flex-direction: column;
    margin-top: 4rem;
}

.reference {
    max-height: 100%;
    line-height: 17px;
    border: 2px solid var(--clr-spcl-blue);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px 30px 0 30px;
    box-sizing: border-box;
    box-shadow: inset 0 0 10px var(--clr-shadow);
}

.reference_container h2 {
    margin: 0;
}

.client h3 {
    font-family: var(--font-geist-bold);
    color: var(--clr-text);
    font-size: var(--fs-client-h3);
    line-height: var(--lh-client-h3);
    margin: 0;
    margin-top: 1.5rem;
}

.client h4 {
    font-family: var(--font-geist-semibold);
    color: var(--clr-text);
    font-size: var(--fs-client-h4);
    line-height: var(--lh-client-h4);
    margin: 0;
}

.client p {
    font-family: var(--font-geist-regular);
    color: var(--clr-secondary);
    font-size: var(--fs-client-p);
    margin-top: 0.5rem;
}

.client:nth-child(1) h3 {
    margin-top: 0.3rem;
}

.client:nth-child(5) p {
    padding-bottom: 1rem;
}

/* Scrollbar */
.reference::-webkit-scrollbar {
    width: 1rem;
}

.reference::-webkit-scrollbar-track {
    background: var(--clr-scroll-track);
}

.reference::-webkit-scrollbar-thumb {
    background: var(--clr-scroll-thumb);
}

@media (max-width: 1280px) {
    .container {
        --fs-client-h3: 2.1vw;
        --fs-client-h4: 2vw;
        --fs-client-p: 2vw;

        --lh-client-h3: 2.4vw;
        --lh-client-h4: 2.3vw;
    }

    .container {
        max-height: 500px;
        max-width: 75%;
        margin-top: 8rem;
    }

    .client h4 {
        margin-top: 0.6vw;
    }

    .client p {
        margin-top: 1.2vw;
    }
}

@media (max-width: 1080px) {
    .container {
        margin-top: 5rem;
    }
}

@media (max-width: 768px) {
    .container {
        --fs-client-h3: 5vw;
        --fs-client-h4: 4vw;
        --fs-client-p: 4vw;

        --lh-client-h3: 6vw;
        --lh-client-h4: 6vw;
        --lh-client-p: 6vw;
    }

    .container {
        max-width: 80%;
        align-self: center;
        margin-top: 16vw;
    }

    .client h3 {
        margin-top: 6vw;
    }
}
