.container {
    --fs-h3: 18px;
    --fs-p: 15px;
}

.line {
    width: 90%;
    height: 2px;
    position: absolute;
    right: 0;
    background-color: var(--clr-spcl-red);
}

.wrapper {
    display: flex;
    justify-content: center;
    width: 80%;
    gap: 100px;
    margin: auto;
    padding-bottom: 4rem;
    margin-top: 1.5rem;
}

.wrapper p {
    font-size: var(--fs-p);
    line-height: calc(var(--fs-p) + 0.5rem);
    font-family: var(--font-geist-thin);
    color: var(--clr-white);
    letter-spacing: 1px;
    margin: 0;
}

.left h3 {
    font-size: var(--fs-h3);
    font-family: var(--font-geist-bold);
    color: var(--clr-white);  
    letter-spacing: 0;
    margin: 0;
    padding-top: 0.15rem;
    padding-bottom: 0.45rem;
}

.logo {
    padding-bottom: 0.45rem;
}

@media (max-width: 1280px) {
    .container {
        --fs-h3: 2vw;
        --fs-p: 1.45vw;
    }

    .wrapper {
        gap: 70px;
    }
}

@media (max-width: 980px) {
    .container {
        --fs-h3: 2.5vw;
        --fs-p: 1.8vw;
    }  
}

@media (max-width: 768px) {
    .container {
        --fs-h3: 3.5vw;
        --fs-p: 3vw;
    }

    .wrapper {
        flex-direction: column;
        width: 75%;
        gap: unset;
    }
    
    .left h3 {
        padding-bottom: 0.5rem;
    }
    
    .logo {
        width: 13vw;
        padding-bottom: 0.5rem;
        margin-top: 1rem;
    }

    .wrapper p {
        line-height: 5vw;
    }
}
