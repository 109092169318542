.button {
    --clr-bg: #FFFFFF;
    --clr-text: #3E3E3E;
    --clr-shadow: #00000066;

    --fs-text: 28.7px;
}

.button {
    height: 58px;
    width: 275px;
    background-color: var(--clr-bg);
    border: 1px var(--clr-bg) solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 3px 3px 3px var(--clr-shadow);
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
}

.button p {
    font-size: var(--fs-text);
    font-family: var(--font-geist-semibold);
    color: var(--clr-text);
    letter-spacing: 0.8px;
    padding-left: 1.25rem;
    margin: 0;
}

.button img {
    height: 100%;
    width: auto;
    padding-right: 0.8rem;
}

.button:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    .button {
        --fs-text: 5.5vw;
    }

    .button {
        height: 11vw;
        width: 53vw;
        border-radius: 0.5vw;
    }

    .button p {
        padding-left: 3vw;
    }

    .button img {
        height: 7.5vw;
        padding-right: 2.5vw;
    }
}

@media (max-width: 200px) {
    .button {
        --fs-text: 4vw;
    }
}