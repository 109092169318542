.container {
    --fs-izkusnje-banner-text: 4.1rem;
}

.container {
    display: flex;
    justify-content: space-between;
    margin-top: 4.9rem;
}

.container > * {
    max-width: 50%;
}
  
.banner_container {
    margin-top: -8rem;
}

.banner {
    margin-left: -1.8rem;
}

.banner_text {
    margin-top: -8rem;
    margin-right: -0.5rem;
}

.banner_text p {
    color: var(--clr-spcl-blue);
    font-family: var(--font-geist-black);
    font-size: var(--fs-izkusnje-banner-text);
    text-align: right;
    line-height: calc(var(--fs-izkusnje-banner-text) - 0.5rem);
}

.adobe {
    width: 6rem;
    margin-top: 1rem;
}

@media (max-width: 1280px) {
    .container{
        --fs-izkusnje-banner-text: 5.2rem;
    }

    .banner_container {
        position: absolute;
        margin-top: -6rem;
        right: 0;
    }
    
    .banner {
        width: 38rem;
        margin-left: -6.85rem;
    }
    
    .banner_text {
        margin-right: 0.5rem;
    }

    .banner_text p {
        line-height: var(--fs-izkusnje-banner-text);
    }

    .adobe {
        width: 13vw;
    }
}

@media (max-width: 1080px) {
    .container {
        --fs-izkusnje-banner-text: 7.5vw;
    }

    .banner {
        width: 55vw;
        margin-left: -10.5vw;
    }

    .banner_text {
        margin-top: -13vw;
    }
}

@media (max-width: 768px) {
    .container {
        --fs-izkusnje-banner-text: 14vw;
    }

    .container {
        align-items: flex-end;
        flex-direction: column;
        margin-top: 11%;
    }

    .container > * {
        max-width: 100%;
    }

    .banner_container {
        position: relative;
        margin-top: unset;
        right: unset;
    }

    .izkusnje {
        max-width: 80%;
        align-self: center;
    }

    .adobe {
        width: 25vw;
        margin-top: 4vw;
    }

    .banner {
        width: 100vw;
        margin-left: -16.5vw;
    }

    .banner_text {
        margin-top: -25vw;
    }
}
