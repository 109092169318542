.modal_overlay {
  --clr-bg-modal: #333333B3;
  --clr-bg-nav: #FFFFFF;
  --clr-bg-arrow: #d4d2d2;

  --nav-size: 2.2rem;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-bg-modal);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: center;
  height: 0;
  opacity: 0;
  transition: all 0.6s ease-out;
  justify-content: center;
}

.navigation_container.animate {
  opacity: 1;
}

.navigation_container.expanded {
  height: 100%;
}

.container {
  height: 70vh;
  width: 70vh;
  overflow: hidden;
}

.gallery_images {
  height: 100%;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image {
  height: 70vh;
  width: 70vh;
}

@media (orientation: portrait) {
  .container {
    height: 70vw;
    width: 70vw;
  }
  .image {
    height: 70vw;
    width: 70vw;
  }
}

.navigation_container.expanded .container {
  opacity: 1;
  transition-delay: 0.3s;
}

.navigation_top,
.navigation_bottom {
  display: flex;
  background-color: var(--clr-bg-nav);
  height: var(--nav-size);
  width: 110%;
  border-radius: 5rem;
  align-items: center;
}

.navigation_top {
  justify-content: flex-end;
}

.navigation_bottom {
  gap: var(--nav-size);
  position: relative;
  justify-content: center;
}

.arrow_container {  
  height: 100%;
  width: var(--nav-size);
  background-color: var(--clr-bg-arrow);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Used to keep top nav bar from scaling down upon open/close animation */
.close_container {
  height: 100%;
  width: var(--nav-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  max-height: 60%;
}

.icon_close {
  height: 50%;
  margin-right: 1vw;
}

.arrow:hover,
.icon_close:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: transform 0.2s ease;
}

@media (max-width: 1200px) {
  .icon_close {
    margin-right: 1.5vw;
  }
}

@media (max-width: 1000px) {
  .icon_close {
    margin-right: 2vw;
  }
}
